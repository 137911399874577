import {
  setToken,
  getToken,
  removeToken,
  getDomain,
  UserSessionResult,
} from '@common/services/token';
import { setCookie, getCookie, removeCookie } from './common/utils/cookies';
import { JWT_TOKEN, TIMESPAN, TIMESPAN_HOURS } from './constants';

function goToLoginApp(previousUrl: string = '/') {
  const domain = getDomain(window.location.href);
  const loginDomain = `https://login.${domain}`;
  window.location.href = `${loginDomain}?redirectTo=${window.location.origin}${previousUrl}`;
}

function logout(previousUrl: string = '/') {
  removeToken();
  goToLoginApp(previousUrl);
}

export {
  removeToken,
  setToken,
  getToken,
  goToLoginApp,
  logout,
  setCookie,
  getCookie,
  removeCookie,
  getDomain,
  JWT_TOKEN,
  TIMESPAN,
  TIMESPAN_HOURS,
  type UserSessionResult,
};
