import { JWT_TOKEN, MO_JWT_TOKEN } from '@constants';
import { getCookie, removeCookie, setCookie } from '../utils/cookies';

export type UserSessionResult = {
  accessToken: string;
  expiresAt: number;
  expiresIn: string;
  issuedAt: number;
  refreshToken: string;
  tokenType: string;
  userId: string;
  userName: string;
  session: string;
  isSuccess: boolean;
  errorMessage: string;
};

export function getDomain(url: string, subdomain: boolean = false) {
  const newUrl = new URL(url);

  newUrl.port = '';

  let domain = newUrl.hostname;

  if (!subdomain) {
    // @ts-ignore
    domain = domain.split('.');
    // @ts-ignore
    domain = domain.slice(domain.length - 2).join('.');
  }

  return domain;
}

export function getToken(): UserSessionResult | null {
  const result = getCookie(JWT_TOKEN);

  if (!result) {
    return null;
  }

  try {
    return JSON.parse(result);
  } catch {
    return null;
  }
}

export function setToken(userSession: UserSessionResult) {
  setCookie(JWT_TOKEN, JSON.stringify(userSession));
  setCookie(MO_JWT_TOKEN, userSession?.accessToken);
}

export function removeToken() {
  removeCookie(JWT_TOKEN);
  removeCookie(MO_JWT_TOKEN);
}
