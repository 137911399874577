import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken, refreshAccessToken, setToken } from '@common/services';
import { message } from 'antd';
import { getAppConfigurations } from './environment-variables';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipGlobalErrorMessage?: boolean;
  apiGroup?: 'main' | 'profile';
}

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: CustomAxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
  }
}

const { mainApiEndpoint, profileApiEndpoint } = getAppConfigurations();

export const request = axios.create({
  baseURL: mainApiEndpoint,
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const userSession = getToken();

      if (userSession) {
        const newUserSession = await refreshAccessToken(userSession.refreshToken);
        setToken(newUserSession);

        return request(originalRequest);
      }
    } else if (error.response.status !== 401) {
      const {
        data = { errorMessage: 'There is an error, please contact our administrator for help.' },
      } = error.response;

      if (!error.config.skipGlobalErrorMessage) {
        message.error(typeof data === 'string' ? data : data.errorMessage);
      }
    }

    return Promise.reject(error.response.data);
  }
);

export const applyInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = getToken();
      const { headers, apiGroup } = config as CustomAxiosRequestConfig;
      const baseURL = apiGroup === 'profile' ? profileApiEndpoint : mainApiEndpoint;
      const customConfig = {
        ...config,
        baseURL,
      };

      if (!token) {
        return customConfig;
      }

      return {
        ...customConfig,
        headers: {
          ...headers,
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

applyInterceptor(request);
