import { APP_ROUTES } from '@constants';
import { request } from '@common/utils/request';
import { getAppConfigurations } from '@/common/utils';

const { cognitoAuthDomain, cognitoClientId, domainUrl } = getAppConfigurations();
const authorizeUrl = `${domainUrl}${APP_ROUTES.AUTHORIZE}`;

export function getCognitoLoginUrl(appRedirectUrl: string) {
  const baseUrl = `https://${cognitoAuthDomain}/login`;
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: cognitoClientId,
    redirect_uri: authorizeUrl,
    state: appRedirectUrl,
  });

  return `${baseUrl}?${params.toString()}`;
}

///oauth2/authorize?identity_provider=Google&redirect_uri=http://localhost:3000/sign-in&response_type=CODE&client_id=1jnpt8ogipmldi76cvssmon3d4&state=\/&scope=email openid profile

export function getSocialLoginUrl(appRedirectUrl: string) {
  const baseUrl = `https://${cognitoAuthDomain}/oauth2/authorize`;
  const params = new URLSearchParams({
    identity_provider: 'Google',
    response_type: 'code',
    client_id: cognitoClientId,
    redirect_uri: authorizeUrl,
    state: appRedirectUrl,
  });

  return `${baseUrl}?${params.toString()}`;
}

//https://sso.wisorylab.com/forgotPassword?response_type=code&client_id=1jnpt8ogipmldi76cvssmon3d4&redirect_uri=https%3A%2F%2Fadmin.wisorylab.com%2Fsign-in&state=%2Fadmin

export function getForgotPasswordUrl(appRedirectUrl: string) {
  const baseUrl = `https://${cognitoAuthDomain}/forgotPassword`;
  const params = new URLSearchParams({
    identity_provider: 'Google',
    response_type: 'code',
    client_id: cognitoClientId,
    redirect_uri: authorizeUrl,
    state: appRedirectUrl,
  });

  return `${baseUrl}?${params.toString()}`;
}

export function getUserSession(authorizationCode: string) {
  return request.post<API.UserSessionResult>('/login/token', {
    authorizationCode,
    callbackUrl: authorizeUrl,
  });
}

export function refreshAccessToken(refreshToken: string) {
  return request.post<API.UserSessionResult>(
    '/login/refresh-token',
    { refreshToken },
    { skipGlobalErrorMessage: true }
  );
}

export function getUserInfo() {
  return request.get<API.UserInfoModel>('/api/users/me');
}

export function updatePassword(data: API.UserPasswordChangeParams) {
  return request.put<API.UserInfoModel>(`/api/users/me/password`, data, {
    skipGlobalErrorMessage: true,
  });
}

export function getUserSessions(params?: {
  periodStartDate?: string;
  periodEndDate?: string;
  includedCancelledEvent?: boolean;
}) {
  return request.get<API.CalendarEventModel[]>(`/calendarEvents`, { params });
}

export function sendOTP(emailAddress: string) {
  return request.post<void>(`/login/sendOtp`, { emailAddress });
}

export function sendCognitoOTP(emailAddress: string) {
  return request.post<API.OtpChallengeView>(
    `/login/otp-login`,
    { emailAddress },
    { skipGlobalErrorMessage: true }
  );
}
export function verifyOTP(emailAddress: string, token: string) {
  return request.post<API.UserSessionResult>(`/login`, { emailAddress, token });
}

export function verifyCognitoOTP(
  emailAddress: string,
  session: string,
  challengeName: string,
  challengeAnswer: string
) {
  return request.post<API.UserSessionResult>(
    `/login/otp-answer`,
    {
      emailAddress,
      session,
      challengeName,
      challengeAnswer,
    },
    { skipGlobalErrorMessage: true }
  );
}
export function loginByPassword(emailAddress: string, password: string) {
  return request.post<API.UserSessionResult>(
    `/login/by-password`,
    { emailAddress, password },
    { skipGlobalErrorMessage: true }
  );
}

export function getCompanyDetails() {
  return request.get<API.CompanyModel>(`/api/users/me/companies`);
}

export function getCompanyCoinHistories(companyId: number) {
  return request.get<API.PaginatedResult<API.SessionHistoryModel>>(
    `api/users/me/companies/${companyId}/coin-histories?pageSize=999&currentPage=0&dynamicFilters=`
  );
}

export function getCompanyUsers(companyId: number) {
  return request.get<API.PaginatedResult<API.ProfileCompanyUserModel>>(
    `/api/users/me/companies/${companyId}/users?pageSize=20&currentPage=1&dynamicFilters=`
  );
}

export function updateCompany(companyId: number, params: API.UpdateCompanyParams) {
  return request.put<void>(`/api/companies/${companyId}`, params);
}

export function createAddress(params: API.AddressModel) {
  return request.post<API.AddressModel>(`/api/addresses`, params);
}

export function updateAddress(params: API.AddressModel) {
  return request.put<{ id: number }>(`/api/addresses/${params.id}`, params);
}

export function linkAddressToCompany(
  companyId: number,
  addressId: number,
  params: {
    isPrimaryAddress: boolean;
  }
) {
  return request.post<API.CompanyModel>(
    `/api/companies/${companyId}/addresses/${addressId}`,
    params
  );
}

export function sendOTPToVerifyNewPhoneNumber(userId: number, phoneNumber: string) {
  return request.post<void>(`/api/users/me/phone-number-otp`, { phoneNumber });
}

export function verifyOTPForNewPhoneNumber(userId: number, otp: string, phoneNumber: string) {
  return request.put<void>(`/api/users/me/phone-number`, {
    otp,
    phoneNumber,
  });
}

export function sendOTPToVerifyNewEmailAddress(userId: number, emailAddress: string) {
  return request.post<void>(`/api/users/me/email-otp`, { emailAddress });
}

export function verifyOTPForNewEmailAddress(userId: number, otp: string, emailAddress: string) {
  return request.put<void>(`/api/users/me/email`, { emailAddress, otp });
}

export function updateProfile(userId: number, data: API.PatchOperation[]) {
  return request.patch<API.UserInfoModel>(`/api/users/me`, data);
}

export function createAnonymousUser(data: API.CreateAnonymousUserView) {
  return request.post<API.AnonymousUserResponse>(`/api/v1/anonymous-users`, data, {
    apiGroup: 'profile',
    skipGlobalErrorMessage: true,
  });
}

export function setUserSkills(skillIds: string[]) {
  return request.put<void>(
    `/api/v1/users/me/skills`,
    { skillIds },
    {
      apiGroup: 'profile',
    }
  );
}

export function getAdvisorInterestRequests(companyId: number) {
  return request.get<API.PaginatedResult<API.UserInfoModel>>(
    `/api/companies/${companyId}/users?pageSize=20&currentPage=1&dynamicFilters=`
  );
}

export function getAdvisorAvailablities(
  advisorId: string,
  params: {
    periodFromDate?: string;
    periodToDate?: string;
  }
) {
  return request.get<API.AppointmentAvailabilityDto[]>(`/calendarAvailability/${advisorId}`, {
    params,
  });
}

export function getAdvisorInfo(advisorId: string) {
  return request.get<API.UserInfoModel>(`/api/users/${advisorId}`);
}

export function bookSlot(params: {
  appointmentAvailabilityId: number;
  platformType: string;
  shortDescription: string;
  reason: string;
  reference: string;
}) {
  return request.post<void>('/calendarEvents', params);
}
