export const JWT_TOKEN = 'WISORY_JWT_TOKEN';
export const MO_JWT_TOKEN = 'mo_jwt_token';
export const APP_ROUTES = {
  AUTHORIZE: '/sign-in',
  UNIVERSAL_LOGIN: '/*',
  LOG_OUT: '/logout',
  REGISTER: '/anonymous-user',
};

export const LOGIN_ERROR_CODE = {
  USER_IS_DISABLED: 'user-in-active',
};

export const PHONE_NUMBER_PREFIX = '+';

export enum TIMESPAN {
  ALL_DAY = 'ALL_DAY',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export const TIMESPAN_HOURS = {
  [TIMESPAN.MORNING]: {
    startHours: 8,
    endHours: 12,
  },
  [TIMESPAN.AFTERNOON]: {
    startHours: 13,
    endHours: 17,
  },
  [TIMESPAN.EVENING]: {
    startHours: 17,
    endHours: 19,
  },
  [TIMESPAN.ALL_DAY]: {
    startHours: 8,
    endHours: 17,
  },
};
