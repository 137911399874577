import Cookies from 'js-cookie';
import { getDomain } from '../services';

export function setCookie(key: string, value: string) {
  const rootDomain = getDomain(window.location.href);

  Cookies.set(key, value, {
    domain: rootDomain,
    path: '/',
  });
}

export function removeCookie(key: string) {
  const rootDomain = getDomain(window.location.href);

  Cookies.remove(key, { domain: rootDomain, path: '/' });
}

export function getCookie(key: string) {
  return Cookies.get(key);
}
