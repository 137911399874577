import { isMobile, isTablet } from 'react-device-detect';
import { Api as MainApi, HttpClient as MainHttpClient } from '../api/wisoryApiClient';
import { Api as ProfileApi, HttpClient as ProfileHttpClient } from '../api/profileApiClient';
import { applyInterceptors } from './request2';
import { getAppConfigurations } from './environment-variables';

const { mainApiEndpoint, profileApiEndpoint } = getAppConfigurations();

const baseAPIClient = new MainHttpClient({
  baseURL: mainApiEndpoint,
});

applyInterceptors(baseAPIClient.instance);

const profileApiClient = new ProfileHttpClient({
  baseURL: profileApiEndpoint,
});

applyInterceptors(profileApiClient.instance);

const baseAPI = new MainApi(baseAPIClient);
const profileApi = new ProfileApi(profileApiClient);

export * from './environment-variables';
export const api = {
  profileApi,
  baseAPI,
};
export * from './cookies';

export function waitForElm(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function isTouchDevice() {
  return isMobile || isTablet;
}
