import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken, refreshAccessToken, setToken } from '@common/services';
import { message } from 'antd';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipGlobalErrorMessage?: boolean;
  apiGroup?: 'main' | 'profile';
}

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: CustomAxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: CustomAxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
    patch<T = any>(url: string, data?: any, config?: CustomAxiosRequestConfig): Promise<T>;
  }
}

function pathJoin(parts: string[], sep?: string) {
  var separator = sep || '/';
  var replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

const createRequest = (apiEndPoint: string) => {
  const request = axios.create({
    baseURL: apiEndPoint,
  });

  request.interceptors.request.use(
    (config: any) => {
      const token = getToken();
      const { headers } = config as CustomAxiosRequestConfig;

      if (!token) {
        return config;
      }

      return {
        ...config,
        headers: {
          ...headers,
          Authorization: `Bearer ${token.accessToken}`,
        },
        path: pathJoin([config.baseURL, config.path]),
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  request.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const userSession = getToken();

        if (userSession) {
          const newUserSession = await refreshAccessToken(userSession.refreshToken);
          setToken(newUserSession);

          return request(originalRequest);
        }
      } else if (error.response.status !== 401) {
        const {
          data = { errorMessage: 'There is an error, please contact our administrator for help.' },
        } = error.response;

        if (!error.config.skipGlobalErrorMessage) {
          message.error(typeof data === 'string' ? data : data.errorMessage || error.errorCode);
        }
      }

      return Promise.reject(error);
    }
  );

  return request;
};

export const applyInterceptors = (request: AxiosInstance) => {
  request.interceptors.request.use(
    (config: any) => {
      const token = getToken();
      const { headers } = config as CustomAxiosRequestConfig;

      if (!token) {
        return config;
      }

      return {
        ...config,
        headers: {
          ...headers,
          Authorization: `Bearer ${token.accessToken}`,
        },
        path: pathJoin([config.baseURL, config.path]),
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  request.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const userSession = getToken();

        if (userSession) {
          const newUserSession = await refreshAccessToken(userSession.refreshToken);
          setToken(newUserSession);

          return request(originalRequest);
        }
      } else if (error.response.status !== 401) {
        const {
          data = { errorMessage: 'There is an error, please contact our administrator for help.' },
        } = error.response;

        if (!error.config.skipGlobalErrorMessage) {
          message.error(typeof data === 'string' ? data : data.errorMessage);
        }
      }

      return Promise.reject(error);
    }
  );

  return request;
};

export default createRequest;
