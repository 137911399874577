export function getAppConfigurations() {
  const {
    REACT_APP_SSO_CLIENT_ID,
    REACT_APP_SSO_AUTH_DOMAIN,
    REACT_APP_ROOT_DOMAIN,
    REACT_APP_DOMAIN_URL,
    REACT_APP_MAIN_API_ENDPOINT,
    REACT_APP_PROFILE_API_ENDPOINT,
    REACT_APP_ENVIRONMENT,
  } = process.env;

  return {
    cognitoClientId: REACT_APP_SSO_CLIENT_ID,
    cognitoAuthDomain: REACT_APP_SSO_AUTH_DOMAIN,
    rootDomain: REACT_APP_ROOT_DOMAIN,
    domainUrl: REACT_APP_DOMAIN_URL,
    mainApiEndpoint: REACT_APP_MAIN_API_ENDPOINT,
    profileApiEndpoint: REACT_APP_PROFILE_API_ENDPOINT,
    environment: REACT_APP_ENVIRONMENT,
  };
}
